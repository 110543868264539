/*
HOW TO
MAKE IMAGE ARRAY AND PASS TO preload.startLoad
------------------------------------------------
preload.startLoad(imgArry).then( res => {
	console.log("all done")
});
*/

export let preload = {
	count: 0,
	max : 0,
	arry: [],
	startLoad: function(arry){
		return new Promise((resolve, reject) =>{
			preload.count 	= 0;
			preload.max 	= arry.length;
			preload.arry 	= arry;
			preload.load().then(res => {
				resolve();
			});
		});
	},
	load: function(){
		return new Promise( (resolve, reject) => {
			loadImg();
			function loadImg(){
				var newImg = new Image();
				newImg.src = preload.arry[preload.count];
				//console.log(newImg.src);
				newImg.onload = function(){
					next();
				}
				newImg.onerror = function(){
					next();
				}
				function next(){
					preload.count++;
					if(preload.count < preload.max){
						loadImg();
					}else{
						resolve();
					}
				}
			}
		});
	}
}