import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { movie } from './_movie.js';
let $this;
let $html 		= document.documentElement;
export class Framework {
	constructor(){
		$this = this;
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
		//this.nav();
	}
	
	checkURL(){
		/*this.pageinfo = loc.all();
		console.log(this.pageinfo);
		
		if($this.firstVisit){
			$this.firstVisit = false;
		}else{
			$this.changeContent();
		}
		*/
		$this.afterChangePage();
	}
	
	nav(){
		let $navs = document.querySelectorAll("#global_nav li a");
		//console.log($navs)
		$navs.forEach( ($nav) => {
			$nav.removeEventListener("click", $this.getLink, false);
			$nav.addEventListener("click", $this.getLink, false);
		});
	}
	getLink(e){
		e.preventDefault();
		let $thisnav = this;
		if( !this.classList.contains("active") ){
			let link = $thisnav.getAttribute("href");
			if($thisnav.classList.contains("external")){
				window.open(link, "_blank");
			}else{
				document.querySelector("body").classList.add("disable");
				setTimeout(function(){
					location.href = link;
				}, 150);
			}
			//$this.changeURL(link);
		}
	}
	
	changeURL(link){
		let hideDuration = 100;
		let showDuration = 200;
		window.history.pushState(null, null, link);
		common.closeGlbnav();
		if( !$html.classList.contains("disable") ){
			$html.classList.add("disable");
			setTimeout(function(){
				$this.changeContent(link).then(function(){
					window.scrollTo(0, 0);
					setTimeout(function(){
						$html.classList.remove("disable");
						$this.checkURL();
					}, showDuration);
				});
			}, hideDuration);
		}
	}
	
	changeContent(link){
		return new Promise( (resolve, reject) => {
			fetch(link).then(response=> response.text())
			.then((text) => {
				//console.log(text);
				const parser 		= new DOMParser();
				let loadedContent 	= parser.parseFromString(text, "text/html"),
					ttl 			= loadedContent.title, //ページタイトル
					$content 		= document.querySelector("#wrapper"),	//書き換えるDOM
					content 		= loadedContent.querySelector("#wrapper main"), //ロードしたコンテンツの中身
					contentBody		= loadedContent.querySelector("body");
				let pagename = contentBody.getAttribute("data-pagename");
				//console.log(pagename);
				//console.log(btns);
				//$content.innerHTML = "";
				//console.log(content);
				let $body = document.querySelector("body");
				$body.setAttribute("data-pagename", pagename);
				
				let currentContent = document.querySelector("main");
				//console.log(currentContent);
				currentContent.innerHTML = "";
				//$content.querySelector(".main_content").remove();
				$content.appendChild(content);
				//$content.appendChild(content);
				document.title = ttl; //ページタイトルを書き換え
				
				resolve();
			});
		});
	}
	
	afterChangePage(){
		let pageArry = this.pageinfo.pathArry;
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		if($this.pageinfo.pathArry[ $this.pageinfo.pathLen-1 ] === ""){ //TOP
			movie.init();
			let $imgs = document.querySelectorAll("#main h1 img");
			let arry = [];
			$imgs.forEach( ($img) => {
				if($img.currentSrc != ""){
					arry.push($img.currentSrc);
				}else{
					arry.push($img.getAttribute("src"));
				}
			});
			//console.log(arry);
			preload.startLoad(arry).then(function(){
				common.resize();
				document.querySelector("body").classList.remove("disable");
				let $splash = document.querySelector("#splash");
				setTimeout(function(){
					if($splash){
						$splash.classList.add("show");
						setTimeout(function(){
							$splash.classList.add("remove");
							setTimeout(function(){
								$html.classList.add("remove_splash");
							}, 300);
						}, 900);
					}
				}, 600);
			});
			
			
		}else{ //下層トップ
			if( pageArry[pageArry.length - 1] === "news" ){
				
			}else{
				setTimeout(function(){
					document.querySelector("body").classList.remove("disable");
				}, 100);
			}
		}
		//console.log(pageArry[pageArry.length - 1])
		if(pageArry.length === 4 &&  pageArry[pageArry.length - 2] === "news" ||  pageArry[pageArry.length - 1] === "news"){
			if( $html.classList.contains("mobile") ){
				document.querySelector("body").classList.remove("disable");
			}else{
				let $imgs = document.querySelectorAll(".list_news ul li img");
				let arry = [];
				$imgs.forEach( ($img) => {
					if($img.currentSrc != ""){
						arry.push($img.currentSrc);
					}else{
						arry.push($img.getAttribute("src"));
					}
				});
				//console.log(arry);
				preload.startLoad(arry).then(function(){
					common.resize();
					document.querySelector("body").classList.remove("disable");
					//console.log("resize");
					console.log("image all loaded")
					setTimeout(function(){
						common.resize();
					}, 600);
				})
			}
		}
		//console.log(pageArry)
		common.resize();
		common.nav();
		$this.nav();
	}
}