//http://gizma.com/easing/
export function getEasing(easingName) {
	let ease;
	//t：アニメーションの経過時間」「b：始点」「c：変化量」「d：変化にかける時間
	switch (easingName) {
		case "linear":
			ease = function(t, b, c, d){
				return c*t/d + b;
			}
		break;
		case "easeItQuad":
			ease = function(t,b,c,d){
				t /= d;
				return c*t*t + b;
			}
		break;
		case "easeOutQuad":
			ease = function(t,b,c,d){
				t /= d;
				return -c * t*(t-2) + b;
			}
		break;
		case "easeInOutQuad":
			ease = function(t, b, c, d){
				t /= d/2;
				if (t < 1) return c/2*t*t + b;
				t--;
				return -c/2 * (t*(t-2) - 1) + b;
			};
		break;
		case "easeInOutCubic":
			ease = function(t, b, c, d){
				t /= d/2;
				if (t < 1) return c/2*t*t*t + b;
				t -= 2;
				return c/2*(t*t*t + 2) + b;
			};
		break;
		case "easeInQuart":
			ease = function(t, b, c, d){
				t /= d;
				return c*t*t*t*t + b;
			};
		break;
		case "easeOutQuart":
			ease = function(t, b, c, d){
				t /= d;
				t--;
				return -c * (t*t*t*t - 1) + b;
			};
		break;
		case "easeInExpo":
			ease = function(t, b, c, d) {
				return t == 0
				? b
				: c * Math.pow(2, 10 * (t / d - 1)) + b;
				//return x === 1 ? 1 : 1 - pow(2, -10 * x);
			};
		break;
		case "easeOutExpo":
			ease = function(t, b, c, d) {
				return t == d
				? b + c
				: c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
			};
		break;
		case "easeInOutExpo":
			ease = function(t, b, c, d) {
				if (t == 0) return b;
				if (t == d) return b + c;
				if ((t /= d / 2) < 1)
				return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
				return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
			};
		break;
	}
	return ease;
}