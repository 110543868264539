import { object } from './_obj.js';
import { getEasing } from './_easing.js';
let Block = {
	top: 0,
	show: 0
}

/*
座標
elem.getBoundingClientRect()
*/
let $this;
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		
		this.checkBlock();
		this.checkScroll();
		document.addEventListener("scroll", ()=>{
			$this.topD = window.pageYOffset;
			$this.checkScroll();
		}, false);
	}
	
	checkScroll(){
		$this.btmD = $this.topD + window.innerHeight;
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll(".a");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
	}
	getTop($dom, offset){ //[トップ位置, 表示位置]
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
}

export let ScrollTo = {
	begin: 0,
	end: 0,
	timer: null,
	easing: null,
	start: function(y, duration){
		ScrollTo.easing = getEasing("easeInOutCubic");
		ScrollTo.end 	= y - window.pageYOffset;	//スクロール最終地点
		ScrollTo.begin 	= new Date() - 0;
		const currentY 	= window.pageYOffset; //クリック時のスクロール地点
		
		clearInterval(ScrollTo.timer);
		ScrollTo.timer = setInterval( ()=> {
			let current = new Date() - ScrollTo.begin;
			if(current >= duration){
				current = duration;
				clearInterval(ScrollTo.timer);
			}
			let progress 	= current/duration;
			window.scrollTo( 0, ScrollTo.getCurrentPos(progress, currentY) );
		}, 33);
	},
	getCurrentPos: function(progress, currentY){
		return ScrollTo.end*ScrollTo.easing(progress, 0, 1, 1) + currentY
	}
}