import { shareSNS } from './lib/_share.js';
import { Scroll, ScrollTo } from './lib/_scroll.js';
let $html 		= document.documentElement;

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		common.scrl = new Scroll();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		let $scrollBtn = document.querySelectorAll(`[data-scroll]`);
		$scrollBtn.forEach( ($btn) => {
			$btn.removeEventListener("click", common.scrollToPos, false);
			$btn.addEventListener("click", common.scrollToPos, false);
		});
		
		let $btnMenu = document.querySelectorAll(`[data-menu]`);
		$btnMenu.forEach( ($menu) => {
			$menu.removeEventListener("click", common.toggleMenu, false);
			$menu.addEventListener("click", common.toggleMenu, false);
		});
	},
	closeGlbnav: function(){
		if( $html.classList.contains("menu") ){
			$html.classList.remove("menu");
		}
	},
	toggleMenu: function(){
		if( !$html.classList.contains("menu") ){
			$html.classList.add("menu");
		}else{
			$html.classList.remove("menu");
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		if( $body.getAttribute("data-page") === "news" ){
			if( document.querySelectorAll("[data-article-detail]").length > 0 ){
				
			}else{
				common.resizeNews();
			}
		}
		let kv = document.querySelector("#main");
		if(kv){
			let kvH = kv.clientHeight;
			let diff = winH - kvH;
			if(diff < 0){
				kv.style.top = diff + "px";
			}else{
				kv.style.top = "";
			}
		}
		addressbarH();
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	scrollToPos: function(e){
		e.preventDefault();
		let target = this.getAttribute("href");
		let posY = common.scrl.getTop(document.querySelector("#target"), 0);
		ScrollTo.start(posY[0], 1000);
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	},
	resizeNews: function(){
		let lists = document.querySelectorAll('.list_news ul li');
		lists.forEach( ($list, index) => {
			$list.style.top = "";
		});
		//console.log("resize news")
		document.querySelector(".list_news ul").style.height = "";
		if( lists.length > 0){
			let hs = [0, 0];
			if (window.matchMedia('all and (min-width : 768px)').matches) {
				lists.forEach( ($list, index) => {
					let h = $list.clientHeight;
					$list.style.top = hs[index%2] + "px";
					hs[index%2] += h;
					//console.log(hs);
				});
				let blockH = Math.max.apply(null, hs);
				document.querySelector(".list_news ul").style.height = blockH + "px";
			} else if(window.matchMedia('all and (min-width : 0px)').matches) {
				lists.forEach( ($list, index) => {
					$list.style.top = "";
				});
				document.querySelector(".list_news ul").style.height = "";
			}
		}
	}
}
